import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Col } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

class PageHTTT extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Col md={12}>
                            <p className="mt-4 mb-4">
                               QUY ĐỊNH VÀ HÌNH THỨC THANH TOÁN
                        </p>
                            <p>
                              1. Giao khóa học và thu tiền tận nơi (cod)
                        </p>
                            <p>
                                Bạn cần cung cấp cho <a href="https://nap.edu.vn/">https://nap.edu.vn/</a> chính xác địa chỉ, số điện thoại. Nhân viên giao hàng của 
                                <b>nap.edu.vn</b> sẽ liên lạc với bạnđể giao Mã kích hoạt để bạn có thể tự kích hoạt tài khoản của mình và
                                 tham gia vào học ngay.
                        </p>
                        </Col>
                        <Col md={12}>
                            <p>
                               2. Thanh toán trực tiếp bằng tiền mặt
                        </p>
                            <p>
                                Quý khách đến trụ sở văn phòng <b>Nap.edu.vn</b> để thực hiện thanh toán, nhân viên của <b>Nap.edu.vn</b> sẽ hướng dẫn Quý khách. Quý khách lưu ý khi thanh toán sẽ có “Phiếu thu” 
                        </p>
                            <p>
                               Địa chỉ văn phòng tại Hà Nội: P306, Tòa nhà Sapphire 1, Khu chung cư Goldmark City - 136 Hồ Tùng Mậu, Cầu Giấy, Hà Nội.
                        </p>
                            <p>
                                (Thời gian làm việc: từ 8:30 - 17:30 , từ Thứ 2 đến Thứ 7 hàng tuần) 
                        </p>
                        </Col>
                        <Col md={12}>
                            <p>3. Thanh toán bằng chuyển khoản</p>
                            <p>
                                Bạn có thể đến bất kỳ ngân hàng nào ở Việt Nam (hoặc sử dụng Internet Banking) để chuyển tiền theo thông tin bên dưới:
                            </p>
                            <p>THANH TOÁN QUA TÀI KHOẢN NGÂN HÀNG</p>
                            <p>Các bạn có thể chuyển vào một trong các tài khoản sau:</p>
                            <p>1. Vietcombank<br />
                            STK: 1029.544.218<br />
                            Chủ tài khoản: NGUYEN VAN NGOAI</p>
                            <p>2. MB (Quân đội)<br />
                            STK: 9504.08.1963<br />
                            Chủ tài khoản: NGUYEN VAN NGOAI</p>
                            <p>
                               Nội dung chuyển khoản, bạn ghi rõ: Họ Tên - Đăng ký mua thẻ học
                            </p>
                        </Col>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {},
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageHTTT));
