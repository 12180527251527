import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Col } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

class PageCSHT extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Col md={12}>
                            <p className="mt-4 mb-4">
                               Chính sách đổi, trả hàng và hoàn tiền
                        </p>
                            <p>
                               Các sản phẩm và dịch vụ Khách hàng đã mua ở Nap.edu.vn không thế được trả lại, trừ trường hợp sản phẩm hay dịch vụ đó có ghi rõ là có thế đổi lại.
                                Vì thế Khách hàng hãy xem kĩ mô tả của các sản phẩm và dịch vụ trước khi mua.
                        </p>
                            <p className="mt-5 mb-5">
                                Nap.edu.vn không có chính sách bảo lưu cho các khóa học đã mua.
                        </p>
                        <p>
                            Nap.edu.vn sẽ hoàn lại tiền cho Khách hàng trong trường hợp Khách hàng đã mua một sản phẩm hay dịch vụ ở Nap.edu.vn, 
                            ví dụ như một tài khoản để học, và Nap.edu.vn không thể tiếp tục cung cấp cho Khách hàng việc dụng những sản phấm hay dịch đó. Trong trường hợp 
                            đó, Nap.edu.vn sẽ hoàn lại cho Khách hàng 
                            số tiền tỉ lệ với khoảng thời gian được phép sử dụng còn lại của những sản phẩm hay dịch vụ đó. 
                        </p>
                        <p>
                            Nap.edu.vn hỗ trợ 2 hình thức hoàn lại tiền: 
                        </p>
                        <p>
                            1) Chuyến khoản ngân hàng và 2) Trả tiền mặt tại trụ sở của Nap.edu.vn. Với hình thức chuyến khoản ngân hàng, Khách hàng chịu phí chuyến khoản.
                        </p>
                        </Col>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {},
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageCSHT));
