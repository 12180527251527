/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

class Footer extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    render() {
        return (
            <div className="nap-footer">
                <div className="footer-top">
                    <svg fill="rgb(16, 87, 212)" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 1920 148"><defs></defs><path className="cls-1" d="M1920,148H0L71.8,29.4A90.2,90.2,0,0,1,203.7,28l8.3,8.7a90.2,90.2,0,0,0,133-2.7l.2-.2a90.2,90.2,0,0,1,134.4-1.3l2.5,2.7a90.2,90.2,0,0,0,133.2,0l3.8-4.2a90.2,90.2,0,0,1,133.2,0l3.9,4.3a90.2,90.2,0,0,0,133.1.1l4.2-4.6a90.2,90.2,0,0,1,133,0l4.1,4.5a90.2,90.2,0,0,0,133.2-.2l3.7-4a90.2,90.2,0,0,1,133.2-.2l4.1,4.5a90.2,90.2,0,0,0,133,0l2.8-3.1A90.2,90.2,0,0,1,1575,33.8h0a90.2,90.2,0,0,0,133.1,2.9l8.3-8.7a90.2,90.2,0,0,1,131.9,1.5Z" transform="translate(0 0)" /><path className="cls-2" d="M1920,148H0L71.8,49.4A90.2,90.2,0,0,1,203.7,48l8.3,8.7a90.2,90.2,0,0,0,133-2.7l.2-.2a90.2,90.2,0,0,1,134.4-1.3l2.5,2.7a90.2,90.2,0,0,0,133.2,0l3.8-4.2a90.2,90.2,0,0,1,133.2,0l3.9,4.3a90.2,90.2,0,0,0,133.1.1l4.2-4.6a90.2,90.2,0,0,1,133,0l4.1,4.5a90.2,90.2,0,0,0,133.2-.2l3.7-4a90.2,90.2,0,0,1,133.2-.2l4.1,4.5a90.2,90.2,0,0,0,133,0l2.8-3.1A90.2,90.2,0,0,1,1575,53.8h0a90.2,90.2,0,0,0,133.1,2.9l8.3-8.7a90.2,90.2,0,0,1,131.9,1.5Z" transform="translate(0 0)" /><path className="cls-3" d="M1920,148H0L71.8,69.4A90.2,90.2,0,0,1,203.7,68l8.3,8.7a90.2,90.2,0,0,0,133-2.7l.2-.2a90.2,90.2,0,0,1,134.4-1.3l2.5,2.7a90.2,90.2,0,0,0,133.2,0l3.8-4.2a90.2,90.2,0,0,1,133.2,0l3.9,4.3a90.2,90.2,0,0,0,133.1.1l4.2-4.6a90.2,90.2,0,0,1,133,0l4.1,4.5a90.2,90.2,0,0,0,133.2-.2l3.7-4a90.2,90.2,0,0,1,133.2-.2l4.1,4.5a90.2,90.2,0,0,0,133,0l2.8-3.1A90.2,90.2,0,0,1,1575,73.8h0a90.2,90.2,0,0,0,133.1,2.9l8.3-8.7a90.2,90.2,0,0,1,131.9,1.5Z" transform="translate(0 0)" /></svg>
                </div>
                <div className="footer-content">
                    <Container>
                        <Row>
                            <Col md={4}>
                                <h2 className="nap-footer-title">HỘ KINH DOANH NAPBOOK</h2>
                                <p>GPKD & MST : 01D - 8007465 (19/12/2018)</p>
                                <p>Nơi cấp: UBND quận Bắc Từ Liêm, Hà Nội</p>
                                <p>Địa chỉ : P306, Tòa nhà Sapphire 1, Khu chung cư Goldmark City - 136 Hồ Tùng Mậu, Cầu Giấy, Hà Nội.</p>
                                <p>Chịu trách nhiệm nội dung : <NavLink to=""><strong>Nguyễn Anh Phong</strong></NavLink></p>
                                <p><a href='http://online.gov.vn/Home/WebDetails/72594' target="_blank"><img width="150" alt='' title='' src='https://cdn.nap.edu.vn/assets/img/logoSaleNoti.png'/></a></p>
                            </Col>
                            <Col md={2}>
                                <h2 className="nap-footer-title">Về NAP</h2>
                                <ul className="nap-link-footer">
                                    <li>
                                        <NavLink to="">Giới thiệu</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="">Học sinh tiêu biểu</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="">Điều khoản chính sách</NavLink>
                                    </li>
                                </ul>
                            </Col>
                            <Col md={3}>
                                <h2 className="nap-footer-title">Dành cho học viên</h2>
                                <ul className="nap-link-footer">
                                    <li>
                                        <NavLink to="mailto:info@nap.vn">Email: napbook.vn@gmail.com</NavLink>
                                    </li>
                                    <li>
                                        <p>Hotline : <NavLink to="tel:0936 22 11 20">0936 22 11 20</NavLink></p>
                                    </li>
                                    <li>
                                        <NavLink to="/page/dieu-khoan-chung">Điều khoản chung</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/page/chinh-sach-bao-mat-thong-tin">Chính sách bảo mật thông tin</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/page/hinh-thuc-thanh-toan">Hình thức thanh toán</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/page/chinh-sach-hoan-tien">Chính sách hoàn tiền</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/page/huong-dan-su-dung">Hướng dẫn sử dụng</NavLink>
                                    </li>
                                </ul>
                            </Col>
                            <Col md={3}>
                                <h2 className="nap-footer-title">HÓA HỌC CÙNG THẦY NAP</h2>
                                <a className="nap-link-fb-page" target="blank" href="https://www.facebook.com/groups/620036341688988">NHÓM HÓA HỌC</a>
                                <div className="fb-page fb_iframe_widget"
                                    data-href="https://www.facebook.com/Th%E1%BA%A7y-Nguy%E1%BB%85n-Anh-Phong-Gi%C3%A1o-vi%C3%AAn-luy%E1%BB%87n-thi-H%C3%B3a-H%E1%BB%8Dc-100162004811335/" data-small-header="false" data-adapt-container-width="true" data-height="500" data-hide-cover="false"
                                    data-show-facepile="true" fb-xfbml-state="rendered" fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=262&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FTh%25E1%25BA%25A7y-Nguy%25E1%25BB%2585n-Anh-Phong-Gi%25C3%25A1o-vi%25C3%25AAn-luy%25E1%25BB%2587n-thi-H%25C3%25B3a-H%25E1%25BB%258Dc-100162004811335%2F&amp;locale=vi_VN&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false">
                                    <span style={{ verticalAlign: 'bottom', width: '250px', height: '196px' }}>
                                        <iframe name="fc4b2458be31" width="254px" height="500px" data-testid="fb:page Facebook Social Plugin"
                                            title="fb:page Facebook Social Plugin" frameBorder="0" allowtransparency="true" allowFullScreen={true}
                                            scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v2.12/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df13cefe0be4b44%26domain%3Dnguyenanhphong.vn%26origin%3Dhttps%253A%252F%252Fnguyenanhphong.vn%252Ff27f303fb0ec4ec%26relation%3Dparent.parent&amp;container_width=262&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FTh%25E1%25BA%25A7y-Nguy%25E1%25BB%2585n-Anh-Phong-Gi%25C3%25A1o-vi%25C3%25AAn-luy%25E1%25BB%2587n-thi-H%25C3%25B3a-H%25E1%25BB%258Dc-100162004811335%2F&amp;locale=vi_VN&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false"
                                            style={{ border: 'none', visibility: 'visible', width: '262px', height: '196px' }}></iframe></span></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="nap-footer-bottom">
                                    <a target="blank" href="https://shopee.vn/napbook.vn">
                                        <img height="40px" src="https://cf.shopee.vn/file/01b6a6bb4299c76fbc76971512c26cd4" title="" alt="" />
                                    </a>
                                    <a target="blank" href="https://www.youtube.com/channel/UCUgL1ZeBwDdwERVKa2wUKuw">
                                        <img style={{ background: '#fff', borderRadius: '10px', height: '40px' }} src="https://cdn.nap.edu.vn/assets/img/youtube-logo.png" title="" alt="" />
                                    </a>
                                    <a target="blank" href="https://tiki.vn/cua-hang/nha-sach-napbook">
                                        <img style={{ height: '40px', background: '#fff', borderRadius: '10px' }} src="https://cdn.nap.edu.vn/assets/img/logo-tiki.png" title="" alt="" />
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {},
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
