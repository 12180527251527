import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Col } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

class PageDKC extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    render() {
        return (
            <>
            <Header/>
                <div className="nap-main-content">
                    <Container>
                       <Col md={12}>
                        <p className="mt-4 mb-4">
                                ĐIỀU KHOẢN SỬ DỤNG DỊCH VỤ:
                        </p>
                            <p>
                                Điều khoản sử dụng dịch vụ Nap.edu.vn này (sau đây gọi tắt là “Điều khoản sử dụng”)
                                là một thỏa thuận/hợp đồng điện tử được thiết lập và ràng buộc các quyền và nghĩa vụ
                                giữa Nap.edu.vn  và học viên  khi sử dụng dịch vụ.
                        </p>
                            <p>
                                Để truy cập và sử dụng Nap.edu.vn,
                                Khách hàng vui lòng xem xét cẩn trọng và xác nhận đồng ý với Điều khoản
                                sử dụng này trước khi bắt đầu sử dụng Nap.edu.vn với các điều khoản và điều kiện sau đây:
                        </p>
                       </Col>
                        <Col md={12}>
                            <b>
                                1. Các điều khoản về việc sử dụng
                        </b>
                            <p>
                                Với việc sử dụng Nap.edu.vn, Khách hàng đồng ý tuân theo tất cả những điều khoản dưới đây:
                        </p>
                            <p>
                                Khách hàng sẽ không tạo tài khoản ở Nap.edu.vn với các thông tỉn giả tạo hay không chính xác về tên họ, email và số điện thoại.
                        </p>
                            <p>
                                Khi tạo tài khoản ở Nap.edu.vn, Khách hàng đồng ý nhận những email cập nhật các tính năng và bài viết mới, cũng như những email
                                thông báo các khuyến mãi ở Nap.edu.vn. Trong những email
                                như vậy, Nap.edu.vn sẽ cung cấp đường link ở cuối email để Khách hàng có thể hủy đăng kí nhận thông báo đó băng cách kích vào link đó.
                        </p>
                            <p>
                                Khách hàng sẽ không truy cập Nap.edu.vn bằng các chương trình tự động.
                        </p>
                            <p>
                                Mỗi một tài khoản sử dụng chỉ được sử dụng, quản lý bởi một người sử dụng duy nhất. Tuyệt đối không được có hành vi chia sẻ,
                                cung cấp thông tin tài khoản cho người khác sử dụng để đăng nhập vào hệ
                                thống của Nap.edu.vn. Việc từ 2 người trở lên cùng sử dụng chung một tài khoản khi bị phát hiện sẽ bị xóa tài khoản ngay lập tức.
                        </p>
                            <p>
                                Trong quá trình sử dụng ở Nap.edu.vn, tất cả những dữ liệu, bao gồm các thông tin về kết quả và lịch sử học tập của Khách hàng, tài liệu học
                                tập, hình ảnh, âm thanh và tất cả các dữ liệu khác do Khách hàng tạo
                                ra hay được thu thập bởi Nap.edu.vn thông qua việc học của Khách hàng, dù miễn phí hay không, sẽ là tài sản tuyệt đối và của riêng Nap.edu.vn.
                        </p>
                            <p>
                                Khách hàng sẽ không sao chép, phát tán hay phát hành bất kì tài liệu nào có Nap.edu.vn, dù miễn phí hay không, mà không có sự cho phép băng văn bản của
                                Nap.edu.vn. Tuy nhiên, Khách hàng có thế để đường dẫn Web (link) đến những tài liệu ở Nap.edu.vn.
                        </p>
                            <p>
                                Tuyệt đối không bàn luận về các vấn đề chính trị, kỳ thị tôn giáo, kỳ thị sắc tộc. Không có những hành vi, thái độ làm tổn hại đến
                                uy tín của các sản phẩm, dịch vụ, khóa học trong hệ thống Nap.edu.vn dưới bất kỳ hình thức nào, phương thức nào. Mọi vi phạm sẽ bị
                                tước bỏ mọi quyền lợi liên quan đối với tài khoản hoặc xử lý trước pháp luật nếu cần thiết. Mọi thông tin cá nhân của NSD sẽ được
                                chúng tôi bảo mật, không tiết lộ ra ngoài. Chúng tôi không bán hay trao đổi những thông tin này với bất kỳ một bên thứ ba nào khác.
                                 Như trên đã nói, mọi thông tin đăng ký của NSD sẽ được bảo mật,
                                nhưng trong trường hợp cơ quan chức năng yêu cầu, chúng tôi sẽ buộc phải cung cấp những thông tin này cho các cơ quan chức năng.
                        </p>
                            <p>
                                Nap.edu.vn có toàn quyền xóa, sửa chữa hay thay đổi các dữ liệu, thông tin tài khoản của NSD trong các trường hợp người đó vi
                                 phạm những quy định kể trên mà không cần sự đồng ý của người sử dụng.
                        </p>
                            <p>
                                Nap.edu.vn có thể thay đổi, bổ sung hoặc sửa chữa thỏa thuận này bất cứ lúc nào và sẽ công bố rõ trên Website hoặc các kênh truyền thông chính thức khác của Nap.edu.vn.
                        </p>
                        </Col>
                        <Col md={12}>
                            <b>2. Các điều khoản về việc mua hàng</b>
                            <p>
                                Khi mua một sản phẩm hay dịch vụ ở Nap.edu.vn, ví dụ như mua tài khoản để học, Khách hàng đồng ý tuân theo tất cả những điều khoản sau: 
                            </p>
                            <p>
                                Những sản phấm và dịch vụ mà Khách hàng đã mua ở Nap.edu.vn, ví dụ như các tài khoản học, là để chỉ mình Khách hàng sử dụng. Khách hàng không được phép đề bất kì 
                                người nào khác đăng nhập vào tài khoản của Khách hàng để sử dụng những sản phẩm hay dịch vụ mà Khách hàng đã mua.
                            </p>
                            <p>
                                Khi thực hiện thanh toán ở Nap.edu.vn, Khách hàng đồng ý sẽ cung cấp những thông tin thanh toán xác thực và chính xác nhất.
                            </p>
                            <p>
                                Nếu Khách hàng vi phạm bất kì điều khoản mua hàng nào ở trên, Nap.edu.vn giữ quyền khóa tài khoản của Khách hàng vô thời hạn, và việc này có thể khiến cho Khách hàng 
                                không còn có thế sử dụng những sản phẩm hay dịch vụ mà Khách hàng đã mua ở Nap.edu.vn (nếu có).
                            </p>
                        </Col>
                        <Col md={12}>
                            <b>
                                3. Phương thức thanh toán
                            </b>
                            <p>
                                Để thuận tiện cho Khách hàng, Nap.edu.vn chấp nhận nhiều hình thức thanh toán khác nhau, bao gồm:
                            </p>
                            <p>
                                Thanh toán bằng thẻ quốc tế như Visa/Master được phát hành hợp pháp bởi một tổ chức tín dụng tại Việt Nam.
                            </p>
                            <p>
                                Thanh toán bằng thẻ ATM nội địa có chức năng Internet Banking hoặc QR Code được cung cấp bởi một tổ chức tín dụng tại Việt Nam.
                            </p>
                        </Col>
                        <Col md={12}>
                            <b>
                                4. Chính sách đổi, trả hàng và hoàn tiền
                            </b>
                            <p>
                                Các sản phẩm và dịch vụ Khách hàng đã mua ở Nap.edu.vn không thế được trả lại, trừ trường hợp sản phẩm hay dịch vụ đó có ghi rõ là có thế 
                                đổi lại. Vì thế Khách hàng hãy xem kĩ mô tả của các sản phẩm và dịch vụ trước khi mua.
                            </p>
                            <p>
                                Nap.edu.vn sẽ hoàn lại tiền cho Khách hàng trong trường hợp Khách hàng đã mua một sản phẩm hay dịch vụ ở Nap.edu.vn, 
                                ví dụ như một tài khoản để học, và Nap.edu.vn không thể tiếp tục cung cấp cho Khách hàng việc dụng những sản phấm hay dịch đó. Trong trường hợp đó, 
                                Nap.edu.vn sẽ hoàn lại cho Khách hàng số tiền tỉ lệ với khoảng thời gian được phép sử dụng còn lại của những sản phẩm hay dịch vụ đó. 
                            </p>
                            <p>
                                Nap.edu.vn không có chính sách bảo lưu cho các khóa học đã mua.
                            </p>
                            <p>
                                Nap.edu.vn hỗ trợ 2 hình thức hoàn lại tiền: 1) Chuyến khoản ngân hàng và 2) Trả tiền mặt tại trụ sở của Nap.edu.vn. Với hình thức chuyến 
                                khoản ngân hàng, Khách hàng chịu phí chuyến khoản.
                            </p>
                            <p>
                                Địa chỉ : P306, Tòa nhà Sapphire 1, Khu chung cư Goldmark City - 136 Hồ Tùng Mậu, Cầu Giấy, Hà Nội.
                            </p>
                        </Col>
                        <Col md={12}>
                            <b>
                                5. Toàn bộ thỏa thuận 
                            </b>
                            <p>
                                Tất cả những điều khoản trên là toàn bộ bản hợp đồng giữa Nap.edu.vn và Khách hàng về tất cả những khía
                                 cạnh của việc sử dụng Nap.edu.vn của Khách hàng. Những điều khoản này sẽ thay thế cho những điều khoản ở trước đó. 
                            </p>
                        </Col>
                        <Col md={12}>
                            <b>
                                6. Thông tin liên lạc 
                            </b>
                            <p>
                                Nếu Khách hàng có câu hỏi nào về những điều khoản này, Khách hàng hãy liên lạc với chúng tôi:
                            </p>
                            <p>
                                Email: info@nap.edu.vn
                            </p>
                            <p>
                                Hotline : 0936 22 11 20
                            </p>
                            <p>
                                Địa chỉ : P306, Tòa nhà Sapphire 1, Khu chung cư Goldmark City - 136 Hồ Tùng Mậu, Cầu Giấy, Hà Nội.
                            </p>
                        </Col>
                    </Container>
                </div>
            <Footer/>
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {},
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageDKC));
