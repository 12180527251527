import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FiPhone } from "react-icons/fi";
import { supportOnline } from '../../redux/banner/action';

class SupportBox extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    async componentDidMount() {
        const params = { position: "SUPPORT_ONLINE" };
        await this.props.supportOnline(params);
    }

    fetchSupport() {
        if (this.props.supportOnlineData instanceof Array) {
            return this.props.supportOnlineData.map((obj, i) =>
                <div className="nap-list-support">
                    <div className="support-avatar">
                        {obj.image ? <img src={obj.image} alt="" /> : null}
                    </div>
                    <div className="support-info">
                        <strong>{obj.name}</strong>
                        <span dangerouslySetInnerHTML={{ __html: obj.description }}></span>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (

            <div className="nap-support-box">
                <h2 className="nap-title-support"><FiPhone /> Tư vấn 24/7</h2>
                {this.fetchSupport()}
                <a className="btn-call-now" href="tel:0936221120">
                    <span><FiPhone /></span>
                    <span>Hotline: 0936 22 11 20</span>
                </a>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        supportOnlineData: state.banner.supportOnline
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { supportOnline },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupportBox));
