import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Col } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

class PageCSBMTT extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Col md={12}>
                            <p className="mt-4 mb-4">
                               CHÍNH SÁCH BẢO VỆ THÔNG TIN CÁ NHÂN CỦA NGƯỜI TIÊU DÙNG
                        </p>
                            <p>
                               Người Tiêu Dùng hoặc Khách hàng sẽ được yêu cầu điền đầy đủ các thông tin theo các trường thông tin theo mẫu có sẵn trên website
                                Nap.edu.vn như: Họ và Tên, địa chỉ (nhà riêng hoặc văn phòng), địa chỉ email (công ty hoặc cá nhân), số điện thoại (di động, nhà riêng hoặc văn phòng) và trong mức độ có thể, các tuỳ chọn... 
                               Thông tin này được yêu cầu để đặt và hoàn tất việc đặt hàng online của Khách hàng (bao gồm gửi email xác nhận đặt hàng đến Khách hàng).
                        </p>
                        </Col>
                        <Col md={12}>
                            <p>1. MỤC ĐÍCH THU THẬP THÔNG TIN CÁ NHÂN CỦA NGƯỜI TIÊU DÙNG</p>
                            <p>
                                Cung cấp dịch vụ cho Khách hàng và quản lý, sử dụng thông tin cá nhân của Người Tiêu Dùng nhằm mục đích quản lý cơ sở dữ liệu 
                                về Người Tiêu Dùng và kịp thời xử lý các tình huống phát sinh (nếu có).
                        </p>         
                        </Col>
                        <Col md={12}>
                            <p>2. PHẠM VI SỬ DỤNG THÔNG TIN CÁ NHÂN</p>
                            <p>
                               website Nap.edu.vn  sử dụng thông tin của Người Tiêu Dùng cung cấp để:
                            </p>
                            <ul className="ml-5" style={{lineHeight:"30px"}}>
                                <li>
                                    Cung cấp các dịch vụ đến Người Tiêu Dùng;
                                </li>
                                <li>
                                    Gửi các thông báo về các hoạt động trao đổi thông tin giữa Người Tiêu Dùng và Nap.edu.vn;
                                </li>
                                <li>
                                    Ngăn ngừa các hoạt động phá hủy, chiếm đoạt tài khoản người dùng của Người Tiêu Dùng hoặc các hoạt động giả mạo Người Tiêu Dùng;
                                </li>
                                <li>
                                    Liên lạc và giải quyết khiếu nại với Người Tiêu Dùng;
                                </li>
                                <li>
                                    Xác nhận và trao đổi thông tin về giao dịch của Người Tiêu Dùng
                                </li>
                                <li>
                                    Trong trường hợp có yêu cầu của cơ quan quản lý nhà nước có thẩm quyền.
                                </li>
                            </ul>
                        </Col>
                        <Col md={12}>
                            <p>3. THỜI GIAN LƯU TRỮ THÔNG TIN CÁ NHÂN</p>
                            <p>
                                Không có thời hạn ngoại trừ trường hợp Người Tiêu Dùng gửi có yêu cầu hủy bỏ tới cho Ban quản trị hoặc Công ty giải thể hoặc bị phá sản.
                            </p>
                        </Col>
                        <Col md={12}>
                            <p>
                                4. NHỮNG NGƯỜI HOẶC TỔ CHỨC CÓ THỂ ĐƯỢC TIẾP CẬN VỚI THÔNG TIN CÁ NHÂN CỦA NGƯỜI TIÊU DÙNG
                            </p>
                            <p>
                                Người Tiêu Dùng đồng ý rằng, trong trường hợp cần thiết, các cơ quan/ tổ chức/cá nhân sau có quyền được
                                 tiếp cận và thu thập các thông tin cá nhân của mình, bao gồm:
                            </p>
                            <ul className="ml-5" style={{ lineHeight: "30px" }}>
                                <li>
                                    Ban quản trị.
                                </li>
                                <li>
                                    Bên thứ ba có dịch vụ tích hợp với website Nap.edu.vn
                                </li>
                                <li>
                                   Công ty tổ chức sự kiện và nhà tài trợ
                                </li>
                                <li>
                                    Cơ quan nhà nước có thẩm quyền trong trường hợp có yêu cầu theo quy định tại quy chế hoạt động
                                </li>
                                <li>
                                    Công ty nghiên cứu thị trường
                                </li>
                                <li>
                                    Cố vấn tài chính, pháp lý và Công ty kiểm toán
                                </li>
                                <li>
                                    Bên khiếu nại chứng minh được hành vi vi phạm của Người Tiêu Dùng
                                </li>
                                <li>
                                    Theo yêu cầu của cơ quan nhà nước có thẩm quyền
                                </li>
                            </ul>
                        </Col>
                        <Col md={12}>
                            <p>5. ĐỊA CHỈ CỦA ĐƠN VỊ THU THẬP VÀ QUẢN LÝ THÔNG TIN</p>
                            <p>
                               HỘ KINH DOANH MAPBOOK
                            </p>
                            <p>
                                Địa chỉ: P306, Tòa nhà Sapphire 1, Khu chung cư Goldmark City - 136 Hồ Tùng Mậu, Cầu Giấy, Hà Nội
                            </p>
                            <p>
                                Email: info@nap.edu.vn
                            </p>
                            <p>
                                Hotline : 0936 22 11 20
                            </p>
                        </Col>
                        <Col md={12}>
                            <p>6. PHƯƠNG TIỆN VÀ CÔNG CỤ ĐỂ NGƯỜI TIÊU DÙNG TIẾP CẬN VÀ CHỈNH SỬA DỮ LIỆU THÔNG TIN CÁ NHÂN CỦA MÌNH</p>
                            <p>
                                Người Tiêu Dùng có quyền tự kiểm tra, cập nhật, điều chỉnh hoặc hủy bỏ thông tin cá nhân của mình bằng cách đăng nhập vào website Nap.edu.vn
                                 và chỉnh sửa thông tin cá nhân hoặc yêu cầu Ban quản trị thực hiện việc này.
                            </p>
                            <p>
                               Người Tiêu Dùng có quyền gửi khiếu nại về việc lộ thông tin cá nhân của mình cho bên thứ 3 đến Ban quản trị. Khi tiếp nhận những phản hồi này, Nap.edu.vn sẽ xác nhận lại 
                               thông tin, phải có trách nhiệm trả lời lý do và hướng dẫn Người Tiêu Dùng khôi phục và bảo mật lại thông tin.
                            </p>
                            <p>
                                Các hình thức tiếp nhận thông tin khiếu nại của Người Tiêu Dùng:
                            </p>
                            <ol className="ml-5" style={{ listStyleType: "lower-roman", lineHeight: "30px"}}>
                               <li>
                                   Qua Email: info@nap.edu.vn
                               </li>
                               <li>
                                   Qua SĐT: 0936 22 11 20
                               </li>
                           </ol>
                        </Col>
                        <Col md={12}>
                            <p>7. CAM KẾT BẢO MẬT THÔNG TIN CÁ NHÂN CỦA NGƯỜI TIÊU DÙNG</p>
                            <p>
                                Thông tin cá nhân của Người Tiêu Dùng trên website Nap.edu.vn được Ban quản trị cam kết bảo mật tuyệt đối theo chính sách 
                                bảo mật thông tin cá nhân được đăng tải trên website Nap.edu.vn. Việc thu thập và sử dụng thông tin của mỗi Người Tiêu Dùng chỉ được thực
                                hiện khi có sự đồng ý của Người Tiêu Dùng trừ những trường hợp pháp luật có quy định khác và quy định này.
                            </p>
                            <p>
                                Không sử dụng, không chuyển giao, cung cấp hoặc tiết lộ cho bên thứ 3 về thông tin cá nhân của Người Tiêu Dùng khi không có sự đồng ý 
                                của Người Tiêu Dùng ngoại trừ các trường hợp được quy định tại quy định này hoặc quy định của pháp luật.
                            </p>
                            <p>
                                Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát dữ liệu cá nhân của Người Tiêu Dùng, Ban quản trị có trách nhiệm thông báo và làm việc với 
                                cơ quan chức năng điều tra và xử lý kịp thời, đồng thời thông báo cho Người Tiêu Dùng được biết về vụ việc.
                            </p>
                            <p>
                                Bảo mật tuyệt đối mọi thông tin giao dịch trực tuyến của Người Tiêu Dùng bao gồm thông tin hóa đơn kế toán chứng từ số hóa tại khu vực dữ liệu trung tâm an toàn của  chúng tôi
                            </p>
                        </Col>
                        <Col md={12}>
                            <p>8. CƠ CHẾ TIẾP NHẬN VÀ GIẢI QUYẾT KHIẾU NẠI LIÊN QUAN ĐẾN VIỆC THÔNG TIN CỦA NGƯỜI TIÊU DÙNG</p>
                            <p>
                                Khi phát hiện thông tin cá nhân của mình bị sử dụng sai mục đích hoặc phạm vi, Người Tiêu Dùng gửi email khiếu nại đến email 
                                info@nap.edu.vn hoặc gọi điện thoại tới số 0936 22 11 20 để khiếu nại và cung cấp chứng cứ liên quan tới vụ việc cho Ban quản trị. Ban quản trị cam kết sẽ phản hồi ngay lập 
                                tức hoặc muộn nhất là trong vòng 24 (hai mươi tư) giờ làm việc kể từ thời điểm nhận được khiếu nại.
                            </p>
                        </Col>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {},
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageCSBMTT));
