import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Col } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

class PageHDSD extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Col md={12}>
                            <p className="mt-4 mb-4"><strong>HƯỚNG DẪN SỬ DỤNG DỊCH VỤ</strong></p>
                            <div>
                                <iframe style={{ width: '100%', height: 800}} src="https://cdn.nap.edu.vn/docs/huong_dan_su_dung.pdf" frameBorder={0} title="Hướng dãn sử dụng"></iframe>
                            </div>
                        </Col>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {},
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageHDSD));
