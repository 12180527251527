import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Container, Table } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { examDetail, resetState, createTesting, clickAnswer, initAnswer } from "../../redux/exam/action";
import { FiClock, FiEdit2 } from "react-icons/fi";
import { isUndefined } from "util";
import Countdown from "react-countdown";
import { Modal, message } from "antd";
import { AiTwotoneTrophy } from "react-icons/ai";

class RowExam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {
                background: "green",
            },
            styleTrue: {
                background: "#2196f3",
                color: "#fff",
                border: "#2196f3",
            },
            answer: null,
        };
    }

    async componentDidMount() {
        const data = {
            question_id: this.props.obj._id,
            value: this.state.answer,
        };
        await this.props.initAnswer(data);
        if (this.props.totalAnswer === 0) {

        }
    }


    handleClick = async (answer) => {
        await this.setState({
            answer,
        });
        const data = {
            question_id: this.props.obj._id,
            value: answer,
        };
        await this.props.clickAnswer(data);
    };

    renderButton = () => {
        const data = ["A", "B", "C", "D"];

        return data.map((item, i) => {
            var className = "nap-btn-answer";
            var style = {};
            if (
                (this.state.value === item &&
                    this.state.value === this.state.answer) ||
                this.state.answer === item
            ) {
                style = this.state.styleTrue;
            }
            if (
                this.state.value === item &&
                this.state.value !== this.state.answer
            ) {
                style = this.state.styleFalse;
            }
            return (
                <button key={item} className={className} style={style}
                    onClick={(e) => this.handleClick(item)}>
                    {item}
                </button>
            );
        });
    }

    render() {
        return (
            <div className="nap-question-testing" id={'question' + (this.props.index + 1)}>
                <h2 className="nap-question-title">NAP {this.props.index + 1}: </h2>
                <div className="nap-question-content">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this.props.obj.question,
                        }}
                        className="html-text"
                    ></div>
                </div>
                <div className="nap-exam-answer-buttons">

                    {this.renderButton()}
                </div>
            </div >
        );
    }
}

class DetailTest extends Component {
    constructor(props) {
        super();
        this.state = {
            ModalText: "Bạn có chắc muốn gửi bài không ?",
            visible: false,
            confirmLoading: false,
            totalAnswer: 2,

            ModalTextNotify: "Bạn đã sẵn sàng làm bài kiểm tra chưa ?",
            visibleNotify: true,
            confirmLoadingNotify: false,
            stop: false,

            loading: false,
            visibleResult: false,

            files: [],
            timeStart: null,
            exam: null,
            data: [],
            time: 0,
            questions: [],
            exam_id: null,
            lesson_id: null,
            classroom_id: null,
            statusCode: false,
        };
    }

    componentDidMount = async () => {
        let params = this.props.match.params.id;
        let id = params.split("-");
        this.state.exam_id = id[0];
        this.state.lesson_id = id[1];
        this.state.classroom_id = id[2];
        await this.props.examDetail({ id: this.state.exam_id, classroom_id: this.state.classroom_id });
        if (this.props.data) {
            const {
                questions,
                exam,
                time_req,
                exam_classroom,
            } = this.props.data;

            var timeRemain = 0;
            if (exam_classroom === null) {
                if (
                    exam_classroom === null ? '' : exam_classroom.started_at !== null &&
                        time_req !== null &&
                        exam.time !== null
                ) {
                    var start_at = new Date(exam_classroom.started_at);
                    var currentTime = new Date(time_req);
                    var time = exam.time;

                    var diffDate = Math.floor((currentTime - start_at) / 60000);

                    if (time >= diffDate) {
                        timeRemain = time - diffDate;
                    } else {
                        timeRemain = 0;
                    }
                }
            } else {
                timeRemain = exam ? exam.time : 0;
            }

            await this.setState({
                questions,
                exam,
                time: timeRemain,
                isTime: exam ? exam.time : 0,
            });
        } else {
            this.props.history.goBack();
            return;
        }
    };

    componentDidUpdate = async () => {
        if (this.props.statusCode === 200 && !this.state.statusCode) {
            await this.props.history.push(`/testing/${this.props.dataCreate._id}`);
        }
    }

    fetchRows() {
        if (this.props.data.questions instanceof Array) {
            return this.props.data.questions.map((object, i) => {
                return (
                    <RowExam
                        obj={object}
                        key={object._id}
                        index={i}
                        clickAnswer={this.props.clickAnswer}
                        initAnswer={this.props.initAnswer}
                    />
                );
            });
        }
    }

    async componentWillUnmount() {
        await this.props.resetState();
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = async (e) => {
        e.preventDefault();
        const data = {
            exam_id: this.state.exam_id,
            answers: Object.assign(
                this.props.dataAnswer,
                this.props.initAnswers
            ),
            lesson_id: this.state.lesson_id,
            classroom_id: this.state.classroom_id,

        };

        await this.setState({
            visible: false,
            confirmLoading: false,
            stop: true,
            loading: false,
        });

        message.loading("Đang chấm điểm bài thi", 1).then(async () => {
            await this.props.createTesting(data);
            await this.props.examDetail({ id: this.state.exam_id, classroom_id: this.state.classroom_id });
            await this.setState({
                visibleResult: true,
                files: [],
            });
        });

    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    showModalNotify = () => {
        this.setState({
            visibleNotify: true,
        });
    };

    handleOkNotify = async () => {
        if (this.props.errMessage !== null) {
            await this.props.history.push("/");
        } else {
            await this.setState({
                visibleNotify: false,
                confirmLoadingNotify: false,
                timeStart: Date.now(),
            });
        }
    };

    handleCancelNotify = () => {
        this.props.history.goBack();
    };

    handleCancelResult = () => {
        this.setState({
            visibleResult: false,
        });
    };

    fetchRank() {
        if (this.props.data && this.props.data.top_testings) {
            return this.props.data.top_testings.map((object, i) => {
                return <tr>
                    <td>{i + 1}</td>
                    <td>{object.user.name}</td>
                    <td>{object.point}</td>
                </tr>;
            });
        }
    }

    fetchAnchorLink() {
        if (this.props.data.questions instanceof Array) {
            return this.props.data.questions.map((item, i) => {
                var style = {};
                if (this.props.answers.length !== 0) {
                    const check = this.props.answers.find(
                        ({ question_id }) => question_id === item._id
                    );
                    if (isUndefined(check)) {
                        style = {};
                    } else {
                        style = {
                            background: "#1e88e5",
                            color: "white",
                        };
                    }
                }
                return (
                    <li key={i}>
                        <a href={"#question" + (i + 1)}>
                            <button style={style}>{i + 1}</button>
                        </a>
                    </li>
                );
            });
        }
    }

    percent = (result) => {
        const { num_right, num_wrong } = result;

        const percent =
            (parseFloat(num_right) /
                (parseFloat(num_right) + parseFloat(num_wrong))) *
            100;
        return Math.round(percent * 100) / 100;
    };

    render() {
        const { visible, confirmLoading, ModalText } = this.state;
        const { visibleNotify, confirmLoadingNotify } = this.state;

        var time = 0;

        if (this.state.time !== 0) {
            time = this.state.time;
        }
        var until = time * 60000 + this.state.timeStart;
        const okStyle = {
            backgroundColor: "#007bff",
            borderColor: "#007bff"
        };
        if (!this.props.data.exam)
            okStyle.display = 'none';

        return (
            <>
                <Header />
                {this.state.visibleNotify === true ? (
                    <Modal
                        className="modal-exam"
                        confirmLoading={confirmLoadingNotify}
                        closable={false}
                        visible={visibleNotify}
                        onOk={this.handleOkNotify}
                        okButtonProps={{
                            style: okStyle
                        }}
                        onCancel={this.handleCancelNotify}
                        okText={
                            <span style={{ color: "white" }}>{this.props.errMessage === null && this.props.data.exam ? 'Vào thi luôn' : 'Đóng'}</span>
                        }
                        cancelText="Bỏ qua"
                    >
                        <div className="widget">
                            {this.props.errMessage !== null ? (
                                <p className="widget-info">
                                    {this.props.errMessage}
                                </p>
                            ) : (
                                    <p className="widget-info">
                                        {this.props.data.exam ? 'Bạn đã sẵn sàng làm bài kiểm tra chưa?' : 'Bạn đã làm bài thi này rồi!'}
                                    </p>
                                )}
                        </div>
                    </Modal>
                ) : (
                        <>

                            <div className="nap-main-content">
                                <Container>
                                    <Row>
                                        <Col md={6} className="box-page-title">
                                            <h1 className="nap-page-title">{this.props.data.exam ? this.props.data.exam.name : ''}</h1>
                                        </Col>
                                    </Row>
                                    <Row className="nap-flex-test">
                                        <a className="nap-button-bxh-test" href="#nap-box-question-right">Xếp hạng <span><AiTwotoneTrophy /></span></a>
                                        <div className="nap-box-question-right nap-box-question-responsive">
                                            <div className="top-q-select">
                                                <h2
                                                    className="float-left">Trả lời nhanh</h2><div className="number-data-b">
                                                    <span>{this.props.totalAnswer}/
                                                    {this.state.questions.length}câu</span></div>
                                                <button className="btn-submit-responsive" id="posting-button" onClick={this.showModal}>
                                                    Nộp bài thi</button>
                                                <Modal
                                                    className="modal-exam-notify"
                                                    title="Thông báo"
                                                    closable={false}
                                                    visible={visible}
                                                    onOk={this.handleOk}
                                                    confirmLoading={confirmLoading}
                                                    onCancel={this.handleCancel}
                                                    okText="Gửi bài ngay"
                                                    cancelText="Chưa gửi"
                                                >
                                                    <p>{ModalText}</p>
                                                </Modal>
                                                <ul>
                                                    {this.fetchAnchorLink()}
                                                </ul></div>
                                            <div className="homework-time homework-time-responsive">
                                                <FiClock style={{ fontSize: '15px', marginRight: '5px', marginTop: '4px', display: 'block' }} />
                                                {until ? (
                                                    <Countdown
                                                        daysInHours={true}
                                                        date={until}
                                                        onComplete={this.handleOk}
                                                    >
                                                        <span>Hết thời gian</span>
                                                    </Countdown>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="nap-question-box">
                                            {this.fetchRows()}
                                        </div>
                                        <div className="nap-box-question-responsive">
                                            <button className="btn-submit-responsive btn-submit-responsive-bottom" id="posting-button" onClick={this.showModal}>
                                                Nộp bài thi
                                            </button>
                                        </div>
                                        <div className="nap-box-question-right nap-box-right-test" id="nap-box-question-right">
                                            <div className="top-q-select">
                                                <h2>Trả lời nhanh</h2>
                                                <ul>
                                                    {this.fetchAnchorLink()}
                                                </ul></div><div className="bottom-q-select">
                                                <div className="number-data-b"><FiEdit2 style={{ marginRight: '3px' }} />
                                                    <span>{this.props.totalAnswer}/{this.state.questions.length} câu</span></div><div className="question-level"></div>
                                                <div className="homework-time">
                                                    <FiClock style={{ fontSize: '20px', marginRight: '5px', marginTop: '-10px' }} />
                                                    {until ? (
                                                        <Countdown
                                                            daysInHours={true}
                                                            date={until}
                                                            onComplete={this.handleOk}
                                                        >
                                                            <span>Hết thời gian</span>
                                                        </Countdown>
                                                    ) : null}
                                                </div>
                                                <div className="finish">
                                                    <button className="btn-1" id="posting-button" onClick={this.showModal}>
                                                        Nộp bài thi
                                                        </button>
                                                    <Modal
                                                        className="modal-exam-notify"
                                                        title="Thông báo"
                                                        closable={false}
                                                        visible={visible}
                                                        onOk={this.handleOk}
                                                        confirmLoading={confirmLoading}
                                                        onCancel={this.handleCancel}
                                                        okText="Gửi bài ngay"
                                                        cancelText="Chưa gửi"
                                                    >
                                                        <p>{ModalText}</p>
                                                    </Modal>
                                                </div>
                                            </div>
                                            <div className="nap-detail-test">
                                                <h3 className="nap-page-title">Bảng Xếp Hạng</h3>
                                                <Table className="nap-bxh-test" striped>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Học sinh</th>
                                                            <th>Điểm</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.fetchRank()}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                            < Footer />
                        </>
                    )}
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {    
    return {
        data: state.exam.data,        
        initAnswers: state.exam.initAnswers,
        answers: state.exam.answers,
        dataAnswer: state.exam.dataAnswer,
        totalAnswer: state.exam.totalAnswer,
        result: state.exam.result,
        errMessage: state.exam.errMessage,
        statusCode: state.exam.statusCode,
        dataCreate: state.exam.dataCreate,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { examDetail, resetState, createTesting, clickAnswer, initAnswer },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailTest));



