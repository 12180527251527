import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Row, Col, Container, Breadcrumb, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { showTesting, viewAnswer } from '../../redux/user/action';
import { isUndefined } from 'util';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { FiChevronsDown, FiChevronsUp } from "react-icons/fi";
import { FiHome } from "react-icons/fi";

class RowQuestions extends Component {
    constructor(props) {
        super();
        this.state = {
            styleTrue: {
                background: "#34A853",
                border: "0px",
                color: "white",
            },
            styleFalse: {
                background: "#EA4335",
                border: "0px",
                color: "white",
            },
            answer: null,
            value: null
        };
    }

    componentDidMount() {
        this.setState({
            answer: this.props.obj.answer,
            value: this.props.obj.value,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.answerData !== null) {
            if (nextProps.answerData._id !== this.props.obj._id) {
                this.setState({
                    isOpen: false,
                });
            }
        }
    }


    handlePlyrScript = async () => {
        await this.removeScript();
        await this.addScript();
    };

    addScript = () => {
        const plyrscript = document.createElement('script');
        plyrscript.innerText =
            "var player = new Plyr('#player', {youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 0 }});";

    };

    removeScript = () => {
        var nodeList = document.getElementsByTagName('script');
        for (var i = 0; i < nodeList.length; i++) {
            if (
                nodeList[i].innerText ===
                "var player = new Plyr('#player', {youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 0 }});"
            ) {
                nodeList[i].remove();
            }
        }
    };

    viewAnswer = async () => {
        await this.setState({
            isOpen: !this.state.isOpen,
        });

        if (this.state.isOpen === true) {
            await this.props.viewAnswer({ id: this.props.obj._id });
            this.handlePlyrScript();
        } else {
            await this.removeScript();
        }
    };

    renderButton = () => {
        const data = ["A", "B", "C", "D"];
        return data.map((item, i) => {
            var className = "nap-btn-answer";
            var style = {};
            if (this.state.value !== null) {
                if (
                    (this.state.value === item &&
                        this.state.value === this.state.answer) ||
                    this.state.answer === item
                ) {
                    style = this.state.styleTrue;
                } else if (
                    this.state.value === item &&
                    this.state.value !== this.state.answer
                ) {
                    style = this.state.styleFalse;
                }
            } else {
                style = this.state.styleDefault;
            }
            return (
                <button key={item} className={className} style={style}>
                    {item}
                </button>
            );
        });
    }

    fetchLinkVideo() {
        if (this.props.answerData instanceof Object) {
            let object = this.props.answerData;
            if (object.video_type === 'YOUTUBE') {
                let vID = null;
                if (object.video_link) {
                    const arrLink = object.video_link.split('?v=');
                    vID = arrLink[1];
                }
                return (
                    <div className="nap-video-lesson">
                        <div className="overlay-video"></div>
                        <iframe title="Video" width="100%" height="640" src={`https://www.youtube.com/embed/${vID}?rel=0&modestbranding=1&playsinline=1&controls=1&enablejsapi=1&origin=https%3A%2F%2Fnap.edu.vn&widgetid=0`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                )
            } else {
                if (object.video_link)
                    return (
                        <div className="nap-video-lesson">
                            <iframe title="Video" width="100%" height="640" src={` ${object.video_link}/preview`} frameBorder="0" allow="autoplay; fullscreen"></iframe>
                        </div>
                    )
            }

        }
    }

    render() {
        return (
            <>
                <div className="nap-question-testing">
                    <h2 className="nap-question-title">Nap {this.props.index + 1}: </h2>
                    <div dangerouslySetInnerHTML={{
                        __html: this.props.obj.question
                    }}>
                    </div>
                    <div className="nap-question-idea mt-4">
                        <div className="float-left">
                            {this.renderButton()}
                        </div>
                        <div className="text-center pt-5">
                            <button
                                onClick={this.viewAnswer}
                                className={
                                    this.state.isOpen === true
                                        ? 'edit-btn-view-testing nap-edit-btn-view-testing nap-edit-btn-view-testing-close'
                                        : 'nap-edit-btn-view-testing'
                                }
                                variant="primary">
                                <span className="show-icon-close"><FiChevronsUp /></span><span><FiChevronsDown /></span>{this.state.isOpen === true ? 'Đóng' : `Xem lời giải`}
                            </button>
                        </div>
                    </div>
                    {this.state.isOpen === true &&
                        this.props.answerData !== null ? (
                            <div className="mt-5">
                                <h5 className="text-bold-question">Lời giải</h5>
                                {this.fetchLinkVideo()}
                            </div>
                        )
                        : null
                    }
                </div>
            </>
        );
    }
}

class RowImage extends Component {
    constructor(props) {
        super();
        this.state = {};
    }

    render() {
        return (
            <>
                <div className="nap-embed-question">
                    <img alt="" src={this.props.obj} />
                </div>
            </>
        );
    }
}

class TestDetail extends Component {
    constructor(props) {
        super();
        this.state = {
            questions: [],
        };
    }

    fetchRows() {
        if (this.state.questions instanceof Array) {
            return this.state.questions.map((object, i) => {
                return <RowQuestions
                    obj={object}
                    key={object._id}
                    index={i}
                    viewAnswer={this.props.viewAnswer}
                    answerData={this.props.answerData}
                />;
            });
        }
    }

    fetchRowsImage() {
        if (this.state.answer_files instanceof Array) {
            return this.state.answer_files.map((object, i) => {
                return <RowImage obj={object} key={i} index={i} />;
            });
        }
    }

    fetchRank() {
        if (this.props.dataShowTesting && this.props.dataShowTesting.top_testings) {
            return this.props.dataShowTesting.top_testings.map((object, i) => {
                return <tr>
                    <td>{i + 1}</td>
                    <td>{object.user.name}</td>
                    <td>{object.point}</td>
                </tr>;
            });
        }
    }

    async componentDidMount() {
        const data = {
            id: this.props.match.params.id,
        };
        await this.props.showTesting(data);
        if (this.props.dataShowTesting) {
            const { testing, exam } = this.props.dataShowTesting;
            await this.setState({
                exam_name: exam.name,
                exam_code: exam.code,
                doc_link: exam.doc_link,
                answer_files: testing.answer_files,
                questions: this.props.questions,
                exam_type: exam.type,
                time: exam.time,
                point: testing.point,
                num_right: testing.num_right,
                num_wrong: testing.num_wrong,
                answers: testing.answers,
            })
        }
    }

    render() {
        return (
            <>
                <Header />
                <>
                    <div className="nap-main-content">
                        <Container>
                            <Row>
                                <Col md={6} className="box-page-title">
                                    <h1 className="nap-page-title">
                                        {!isUndefined(
                                            this.state.exam_name,
                                        )
                                            ? this.state.exam_name
                                            : ''}
                                    </h1>
                                </Col>
                                <Col md={6} className="box-breadcrumbs text-right">
                                    <Breadcrumb className="nap-breadcrumb">
                                        <Breadcrumb.Item href="/"><FiHome /></Breadcrumb.Item>
                                        <Breadcrumb.Item href="/my/testings">Bài đã làm</Breadcrumb.Item>
                                        <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                                            {this.state.exam_name ? this.state.exam_name : ''}
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                            <Row className="d-block">
                                <div className="nap-box-question-right nap-box-question-responsive nap-box-testing-detail">
                                    <Row>
                                        <Col xs={12}>
                                            <h2 className="nap-name-testing-detail">
                                                <span style={{ textTransform: "uppercase", fontSize: "18px" }}>Kết quả bài thi</span>
                                            </h2>
                                        </Col>
                                        <Col xs={12}>
                                            <p className="nap-code-testing-detai">
                                                Mã đề: <span style={{ fontWeight: "600" }}>
                                                    {!isUndefined(
                                                        this.state
                                                            .exam_code,
                                                    )
                                                        ? this.state
                                                            .exam_code
                                                        : ''}
                                                </span>
                                            </p>
                                        </Col>
                                        <Col xs={12}>
                                            <p className="nap-code-testing-detai">
                                                Thời gian làm bài: <span style={{ fontWeight: "600" }}>
                                                    {this.state.time !=
                                                        null
                                                        ? this.state
                                                            .time + ' phút'
                                                        : '10 phút'}
                                                </span>
                                            </p>
                                        </Col>
                                        <Col xs={5}>
                                            <p className="nap-code-testing-detai">
                                                Số câu đúng: <span style={{ color: "#3e3b3b", fontWeight: "600" }}>
                                                    {!isUndefined(
                                                        this.state
                                                            .num_right,
                                                    )
                                                        ? this.state
                                                            .num_right
                                                        : ''}/
                                                   {!isUndefined(
                                                            this.state
                                                                .num_right,
                                                        )
                                                        ? this.state
                                                            .num_right + this.state.num_wrong
                                                        : ''}
                                                </span>
                                            </p>
                                        </Col>
                                        <Col xs={7}>
                                            <p className="nap-code-testing-detai" style={{ fontWeight: "700", fontSize: "17px" }}>
                                                Điểm: <span style={{ color: "#d60909", fontWeight: "700" }}>
                                                    {!isUndefined(
                                                        this.state
                                                            .point,
                                                    )
                                                        ? this.state
                                                            .point
                                                        : ''}
                                                </span>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="nap-question-box">
                                    {this.fetchRows()}
                                </div>
                                <div className="nap-box-question-right nap-box-testing-detail">
                                    <h2 className="nap-name-testing-detail">
                                        <span style={{ textTransform: "uppercase" }}>Kết quả bài thi</span>
                                    </h2>
                                    <p className="nap-code-testing-detai">
                                        Mã đề: <span>
                                            {!isUndefined(
                                                this.state
                                                    .exam_code,
                                            )
                                                ? this.state
                                                    .exam_code
                                                : ''}
                                        </span>
                                    </p>
                                    <p className="nap-code-testing-detai">
                                        Thời gian làm bài: <span>
                                            {this.state.time !=
                                                null
                                                ? this.state
                                                    .time + ' phút'
                                                : '10 phút'}
                                        </span>
                                    </p>
                                    <p className="nap-code-testing-detai">
                                        Số câu đúng: <span style={{ color: "#3e3b3b", fontWeight: "700" }}>
                                            {!isUndefined(
                                                this.state
                                                    .num_right,
                                            )
                                                ? this.state
                                                    .num_right
                                                : ''}/
                                                   {!isUndefined(
                                                    this.state
                                                        .num_right,
                                                )
                                                ? this.state
                                                    .num_right + this.state.num_wrong
                                                : ''}
                                        </span>
                                    </p>
                                    <p className="nap-code-testing-detai">
                                        Điểm: <span style={{ color: "#d60909", fontWeight: "700", fontSize: "20px" }}>
                                            {!isUndefined(
                                                this.state
                                                    .point,
                                            )
                                                ? this.state
                                                    .point
                                                : ''}
                                        </span>
                                    </p>
                                    <div className="nap-detail-test">
                                        <h3 className="nap-page-title">Bảng Xếp Hạng</h3>
                                        <Table className="nap-bxh-test" striped>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Học sinh</th>
                                                    <th>Điểm</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.fetchRank()}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Row>
                        </Container>
                    </div>
                    < Footer />
                </>
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        dataShowTesting: state.user.dataShowTesting,
        answerData: state.user.answerData,
        questions: state.user.questions,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { showTesting, viewAnswer },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestDetail));
